<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
      <el-form-item label="角色" prop="roleId">
        <el-select v-model="form.roleId" placeholder="请选择"  @change='chooseRole' disabled>
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属商户" prop="merchantId" v-if="userInfo.roleLevel < 5">
        <el-select v-model="form.merchantId" placeholder="请选择"  @change='chooseManager' disabled>
          <el-option
            v-for="item in merchantOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="账号" prop="accountName" >
        <el-input style="width: 45%;" placeholder="商户代码" v-model="form.merchantCode" disabled></el-input>
        <el-input placeholder="请输入账号" v-model="form.accountName"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone" >
        <el-input placeholder="请输入联系电话" v-model="form.phone">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="add('form')" :plain='true'>立即提交</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
let _this;
import {updateUser,getRoleList,getMerchantList} from '@/api/public.js';
import { phoneRight } from '@/api/publicFun.js';
export default {
  props:["row"],
  data() {
    return {
      form:{
        IsLocked:false,
        roleId:"",
        accountName:"",
        merchantCode:"",
      },
      roleId:'',
      imageUrl:'',
      value:[],
      options:[],
      show:true,
      rules: {
        name: [
          { required: true, message: '用户名不得为空', trigger: 'blur', }
        ],
        accountName: [
          { required: true, message: '登录名不得为空', trigger: 'blur', }
        ],
        roleId: [
          { required: true, message: '角色不得为空', trigger: 'change', }
        ],
        password: [
          { required: true, message: '密码不得为空', trigger: 'blur', }
        ],
        phone: [
          { required: true, message: "联系电话不得为空", trigger: "blur" },
          { validator: phoneRight, trigger: 'blur' }
        ],
      },
      managerId:undefined,
      roleLevel:null,
      userInfo:JSON.parse(localStorage.getItem('userInfo'))
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.init();
    this.$nextTick(()=>{
      let ind = this.row.account.indexOf('-');
      if(ind == -1 && this.row.merchantId){
        this.row.accountName = this.row.account;
        this.row.merchantCode = "";
      }else if(!this.row.merchantId){
        this.row.accountName = this.row.account;
        this.row.merchantCode = "";
      }else{
        this.row.merchantCode = this.row.account.slice(0,ind);
        this.row.accountName = this.row.account.slice(ind+1,this.row.account.length);
      }
      this.form = {...this.row};
      console.log(this.form,887)
    })
  },

  methods: {
    async init(){
      if(this.userInfo.roleLevel < 5){
        // 获取商户列表
        let data = {
          pageNo:1,
          pageSize:1000,
          param:{
            name:'',
          }
        }
        await getMerchantList(data).then(res=>{
          if(res.success){
            this.merchantOptions = res.data.list;
          }else{
            this.merchantOptions = [];
          }
        })
      }
      await getRoleList().then(res=>{
        if(res.success){
          this.options=res.data;
          for(let i=0;i<this.options.length;i++){
            if(this.form.roleId == this.options[i].id){
              this.roleLevel = this.options[i].level;
              break;
            }
          }
        }
      })
    },
    close(){
       this.$emit('close');
    },
    select_name(e){},
    //  选择角色
    chooseRole(e){
      for(let i=0;i<this.options.length;i++){
        if(this.options[i].id == e){
          this.roleLevel = this.options[i].level;
        }
      }
      if(this.roleLevel < 5){
        this.form.merchantCode = "";
        this.form.merchantId = "";
      }
    },
    // 选择商户
    chooseManager(e){
      this.form.merchantCode = null;
      for(let i = 0;i<this.merchantOptions.length;i++){
        if(this.merchantOptions[i].id == e){
          this.form.merchantCode = this.merchantOptions[i].code;
          break;
        }
      }
    },
    // 输入账户
    writeAccount(e){
      console.log(e,998);
      this.form.accountName = e;
    },
    //  提交
    add(form) {
       this.$refs["form"].validate(valid => {
          if (valid) {
            if(this.roleLevel >= 5){
              this.form.account = !this.form.merchantCode || this.form.merchantCode == "" ? this.form.accountName : `${this.form.merchantCode}-${this.form.accountName}`;
            }else{
              this.form.account = this.form.accountName;
            }
            this.form.createTime = "";
            updateUser({param:this.form})
              .then((res) => {
                if (res.success) {
                  this.$message({
                    message: "提交成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    this.$emit('close');
                    this.init();
                  }, 1000);
                }else{
                  this.$message({
                    message: res.msg,
                    type: "fail",
                  });
                }
              })
              .catch(error => {});
          } else {
            return false;
          }
      });
    },
    
  }
};
</script>
<style lang="scss" scoped>
/deep/.el-form-item__content{
  display: flex;
}
/deep/ .el-select{
  width: 100%;
}
 .el-drawer{
   overflow:auto !important;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
