<template>
    <section class="outContainer">
      <!-- 搜索 -->
      <div class="search">
        <el-input class="each" placeholder="用户账号" v-model="searchInfo.param.account">
        </el-input>
        <el-input class="each" placeholder="用户手机号" v-model="searchInfo.param.phone">
        </el-input>
        <el-select class="each" placeholder="用户工作状态" v-model="searchInfo.param.workStatus">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select class='each' placeholder="商户筛选" v-model="searchInfo.param.merchantId" 
         clearable v-if="userInfo.roleLevel < 5"
        >
          <el-option
            v-for="item in merchantOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <el-button size="mini" type="primary" icon="el-icon-search" @click="handleSearch('search')">搜索</el-button>
        <el-button size="mini" type="primary" icon="el-icon-close" @click="handleSearch('clear')">清空搜索条件</el-button>
        <el-button size="mini" @click="handleAdd">新增</el-button>
      </div>
      <div class="tableContainer">
        <!-- 表格 -->
        <el-table
         :data="userInfoList"
         style="width: 100%;"
         height="100%"
         row-key="id"
        >
          <af-table-column align='center' label="账号" prop="account">
          </af-table-column>
          <af-table-column align='center' label="联系电话" prop="phone">
          </af-table-column>
          <af-table-column align='center' label="工作状态" prop="workStatusStr">
          </af-table-column>
          <af-table-column align='center' label="角色" prop="roleName">
          </af-table-column>
          <af-table-column align='center' label="是否启用" prop="disabledStr">
          </af-table-column>
          <af-table-column align='center' label="创建时间" prop="createTime">
          </af-table-column>
          <el-table-column align='center' label="操作" fixed="right" min-width="300">
            <template slot-scope="scope">
              <el-button size="mini" @click="resetPass(scope.row)">重置密码</el-button>
              <el-button size="mini" @click="handleLock(scope.row)">{{scope.row.disabled ? '启用':'禁用'}}</el-button>
              <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">修改</el-button>
              <el-button size="mini" @click="handleClock(scope.row,1)" v-if="scope.row.workStatus == 2">打卡上班</el-button>
              <el-button size="mini" @click="handleClock(scope.row,2)" v-if="scope.row.workStatus == 1">打卡下班</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--分页-->
        <el-col :span="24" class="toolbar pagination">
          <el-pagination
            background
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
            @prev-click="prevpage"
            @next-click="nextpage"
            :page-size="10"
            :total="total"
            style="float:right;"
          ></el-pagination>
        </el-col>
        <!-- 新增 -->
        <el-drawer title="新增"
         :visible.sync="addDrawer"
         size="50%"
         :before-close="handleClose">
          <add v-if="addDrawer" @close='handleClose' ref='newForm' :id="id" :row="row"></add>
        </el-drawer>
        <!-- 修改 -->
        <el-drawer title="修改"
         :visible.sync="editDrawer"
         size="50%"
         :before-close="handleClose">
          <edit v-if="editDrawer" @close='handleClose' ref='newForm' :id="id" :row="row"></edit>
        </el-drawer>
      </div>
    </section>
</template>
  
  <script>
  import {getUserList,updateUserWork,forbiddenUser,resetUserPassword,getMerchantList} from '@/api/public.js';
  import { timeChange } from '@/api/publicFun.js';
  import add from './add.vue';
  import edit from './edit.vue';
  
  export default {
    data() {
      return {
        searchInfo:{
          pageNo:1,
          pageSize:10,
          param:{
            account: "",
            phone: "",
            workStatus: "",
            merchantId:"",
          }
        },
        userInfoList: [],
        addDrawer:false,
        editDrawer:false,
        id:'',
        row:'',
        pageNo:1,
        pageSize:10,
        direction:'rtl',
        total:0,
        options:[
          {label:'上班中',value:1},
          {label:'休息中',value:2},
        ],
        userInfo:JSON.parse(localStorage.getItem('userInfo')),
        merchantOptions:[], // 商户选项
      }
    },
  
    components: {add,edit},
  
    computed: {},
  
    mounted() {
      this.init();
      this.userInfo.roleLevel < 5 ? this.getMerchantOptions() : '';
    },
    methods: {
      // 重置密码
      resetPass(row){
        this.$confirm('确认重置该账户的密码吗？').then(res=>{
          let data = {
            userId:row.id
          }
          resetUserPassword({param:data}).then(res=>{
            if(res.success){
              this.$confirm(`重置密码成功，重置后的密码为：${res.data}`)
            }
          })
        })
      },
      // 条件筛选事件
      handleSearch(type){
        if(type == 'clear'){
          this.searchInfo.param = {};
        }
        this.searchInfo.pageNo = 1;
        this.init();
      },
      // 页面初始化
      async init(){
        await getUserList(this.searchInfo).then(res=>{
          if(res.success){
            this.userInfoList=res.data.list;
            this.total=res.data.total;
            this.userInfoList.forEach((element) => {
              let eachTime = timeChange(element.createTime);
              element.createTime = `${eachTime.year}-${eachTime.month}-${eachTime.day}  ${eachTime.hour}:${eachTime.minutes}:${eachTime.seconds}`;
            });
          }else{
            this.userInfoList = [];
            this.total = 0;
            this.$message({
              message:res.msg,
              type:'fail',
            })
          }
        });
      },
      // 获取商户列表
      async getMerchantOptions(){
        let data = {
          pageNo:1,
          pageSize:1000,
          param:{
            name:'',
          }
        }
        await getMerchantList(data).then(res=>{
          if(res.success){
            this.merchantOptions = res.data.list;
          }else{
            this.merchantOptions = [];
          }
        })
      },
      // 打开新增
      handleAdd(index,row){
        this.addDrawer=true;
      },
      // 打开修改
      handleEdit(index,row){
        this.editDrawer=true;
        this.id=index;
        this.row=row;
      },
      // 关闭
      handleClose(){
        this.row = null;
        this.addDrawer=false;
        this.editDrawer=false;
        this.init();
      },
      // 删除
      handleDel(index,row){
        // await this.$confirm('确认删除该用户吗？', '提示', {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     type: 'warning'
        //   }).then(() => {
        //     deleteUser({Id:row.Id}).then(res=>{
        //       if(res.Success){
        //         this.$message({
        //           message: "删除成功",
        //           type: "success",
        //         });
        //         this.init();
        //       }
        //     })
        //   }).catch(() => {
        //     this.$message({
        //       type: 'info',
        //       message: '已取消删除'
        //     });          
        //   });
      },
      // 冻结、解冻
      handleLock(row){
        this.$confirm('确认启用/禁用该用户吗？').then(() => {
          let param = new URLSearchParams();
          param.append("userId", row.id);
          param.append("status", !row.disabled);
          forbiddenUser(param).then(res=>{
            if(res.success){
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.init();
            }
          })
        })
      },
      // 修改、新增、成功后
      handleDrawerSuccess(){
        this.addDrawer=false;
        this.editDrawer=false;
        this.init();
      },
      // 打卡
      handleClock(row,type){
        this.$confirm('确认进行打卡吗？').then(async (res)=>{
          let param = new URLSearchParams();
          param.append("userId", row.id);
          param.append("code", type);
          await updateUserWork(param).then(res=>{
          if(res.success){
              this.$message({
                message:'打卡成功',
                type:'success'
              })
            }else{
              this.$message({
                message:'打卡失败，请重试',
                type:'fail'
              })
            }
            this.init();
          })
        })
      },
      // 分页
      prevpage(val){
        this.searchInfo.pageNo=val;
        this.init();
      },
      nextpage(val){
        this.searchInfo.pageNo=val;
        this.init();
      },
      handleCurrentChange(val){
        this.searchInfo.pageNo =val;
        this.init();
      },
    },
  };
  </script>
  <style scoped>
    .user{
      width: 100%;
      background-color: white;
      height: 60px;
      width: 100%;
      padding: 10px 50px;
      position: relative;
    }
    .user button{
      position: absolute;
      right:20px;
      top:10px;
    }
  </style>
  