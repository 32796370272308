<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
      <el-form-item label="角色" prop="roleId">
        <el-select v-model="form.roleId" placeholder="请选择"  @change='chooseRole'>
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属商户" prop="merchantId" v-if="roleLevel >= 5 && userInfo.roleLevel < 5">
        <el-select v-model="form.merchantId" placeholder="请选择"  @change='chooseManager'>
          <el-option
            v-for="item in merchantOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="账号" prop="accountName" >
        <el-input style="width: 45%;" placeholder="商户代码" v-model="form.merchantCode" disabled></el-input>
        <el-input placeholder="请输入账号" v-model="form.accountName"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password" >
        <el-input placeholder="请输入密码" v-model="form.password">
        </el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone" >
        <el-input placeholder="请输入联系电话" v-model="form.phone">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="add('form')" :plain='true'>立即添加</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
let _this;
import { addUser,getRoleList,getMerchantList} from '@/api/public.js';
import { phoneRight } from '@/api/publicFun.js';
export default {
  data() {
    return {
      form:{
        IsLocked:false,
        roleId:"",
        workStatus:1,
      },
      roleId:'',
      imageUrl:'',
      value:[],
      options:[], // 角色选项
      merchantOptions:[], // 商户选项
      show:true,
      rules: {
        name: [
          { required: true, message: '用户名不得为空', trigger: 'blur', }
        ],
        accountName: [
          { required: true, message: '账号不得为空', trigger: 'blur', }
        ],
        roleId: [
          { required: true, message: '角色不得为空', trigger: 'change', }
        ],
        password: [
          { required: true, message: '密码不得为空', trigger: 'blur', }
        ],
        phone: [
          { required: true, message: "联系电话不得为空", trigger: "blur" },
          { validator: phoneRight, trigger: 'blur' }
        ],
        merchantId:[
          { required: true, message: "所属商户不得为空", trigger: "blur" },
        ]
      },
      merchantId:undefined,
      roleLevel:null,
      userInfo:JSON.parse(localStorage.getItem('userInfo'))
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.form.merchantCode = this.userInfo.merchantInfo ? this.userInfo.merchantInfo.code : "";
    _this=this;
    _this.init();
  },

  methods: {
    async init(){
      await getRoleList().then(res=>{
        if(res.success){
          this.options = res.data;
        }
      })
      // 获取商户列表
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:'',
        }
      }
      await getMerchantList(data).then(res=>{
        if(res.success){
          this.merchantOptions = res.data.list;
        }else{
          this.merchantOptions = [];
        }
      })
    },
    close(){
       _this.$emit('close');
    },
    select_name(e){},
    //  选择角色
    chooseRole(e){
      for(let i=0;i<this.options.length;i++){
        if(this.options[i].id == e){
          this.roleLevel = this.options[i].level;
        }
      }
      if(this.roleLevel < 5){
        this.form.merchantCode = "";
        this.form.merchantId = "";
      }
      console.log(this.form,987)
    },
    // 选择商户
    chooseManager(e){
      this.form.merchantCode = null;
      for(let i = 0;i<this.merchantOptions.length;i++){
        if(this.merchantOptions[i].id == e){
          this.form.merchantCode = this.merchantOptions[i].code;
          break;
        }
      }
    },
    //  添加
    add(form) {
      _this.form.IsLocked=false;
      console.log(this.roleLevel,998)
      if(this.roleLevel >= 5){
        this.form.account = !this.form.merchantCode || this.form.merchantCode == "" ? this.form.accountName : `${this.form.merchantCode}-${this.form.accountName}`;
      }else{
        this.form.account = this.form.accountName;
      }
      _this.$refs["form"].validate(valid => {
          if (valid) {
            this.form.createTime = "";
            addUser({param:this.form})
              .then((res) => {
                if (res.success) {
                  _this.$message({
                    message: "添加成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    _this.$emit('close');
                    _this.init();
                  }, 1000);
                }else{
                  _this.$message({
                    message: res.msg,
                    type: "fail",
                  });
                }
              })
              .catch(error => {});
          } else {
            return false;
          }
      });
    },
    
  }
};
</script>
<style lang="scss" scoped>
/deep/.el-form-item__content{
  display: flex;
}
/deep/ .el-select{
  width: 100%;
}
 .el-drawer{
   overflow:auto !important;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
